import React, { useCallback, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Minter } from '../../blockchain/Minter';

const SupplyStatus = () => {
  const [remainingSupply, setRemainingSupply] = useState({ loading: true, value: 0 });
  const [totalSupply, setTotalSupply] = useState({ loading: true, value: 0 });

  const retrieveSupply = useCallback(async () => {
    const minter = new Minter();
    minter.getTotalSupply()
    .then((result) => {
      if(typeof result === 'string' && parseInt(result) >= 0) {
        setTotalSupply({ loading: false, value: result });
      } else {
        throw new Error("Unable to retrieve total supply");
      }
    }).catch((err) => {
      setTotalSupply({ loading: false, value: 0 });
    });
    minter.getRemainingSupply()
    .then((result) => {
      if(typeof result === 'string' && parseInt(result) >= 0) {
        setRemainingSupply({ loading: false, value: result });
      } else {
        throw new Error("Unable to retrieve remaining supply");
      }
    }).catch(() => {
      setRemainingSupply({ loading: false, value: 0 });
    });
    
  }, []);

  useEffect(() => {
    retrieveSupply();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(!remainingSupply.loading && remainingSupply.value === 0) {
      console.log('No more');
    }
  }, [remainingSupply]);

  return (
    <div className="sos-supply-status-container">
      {
        remainingSupply.loading ? 
        <Spinner animation="grow" className="sos-supply-spinner" id="sos-supply-spinner-remaining"/> :
        <p className="sos-status-text">{remainingSupply.value}</p>
      }
      <p className="sos-supply-status-divider">/</p>
      {
        totalSupply.loading ? 
        <Spinner animation="grow" className="sos-supply-spinner" id="sos-supply-spinner-total"/> :
        <p className="sos-status-text">{totalSupply.value}</p>
      }
    </div>
  );
};

export default SupplyStatus;
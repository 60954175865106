import React, { useCallback, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { isChrome, isEdge, isFirefox } from 'react-device-detect';


const METAMASK_INSTALL = {
  BRAVE: {
    link: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
    logo: '/images/browsers/brave.png',
  },
  CHROME: {
    link: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
    logo: '/images/browsers/chrome.png',
  },
  EDGE: {
    link: 'https://microsoftedge.microsoft.com/addons/detail/metamask/ejbalbakoplchlghecdalmeeeajnimhm?hl=en-US',
    logo: '/images/browsers/edge.png',
  },
  FIREFOX: {
    link: 'https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/',
    logo: '/images/browsers/firefox.png',
  },
}

const BrowserExtensionInstaller = () => {
  const browserExtension = useMemo(() => {
    let installer = {
      logo: '',
      link: ''
    };
    // console.log(JSON.stringify({ browserName, isBrave: !!window.google }));
    if(isChrome) {
      installer = METAMASK_INSTALL.CHROME;
    } else if(isEdge) {
      installer = METAMASK_INSTALL.EDGE
    } else if(isFirefox) {
      installer = METAMASK_INSTALL.FIREFOX;
    }
    return installer;
  }, []);
  
  const installExtension = useCallback(() => {
    const win = window.open(browserExtension.link, "_blank");
    win.focus();
  }, [browserExtension]);

  return (
    <Button variant="dark" className="d-flex align-items-center" onClick={installExtension}>
      <img src={browserExtension.logo} alt="Browser Logo" className="sos-browser-logo display-flex"/>
      <p className="mb-0">Install Metamask Extension</p>
    </Button>
  );
};

export default BrowserExtensionInstaller;
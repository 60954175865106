import React from 'react';
import { Modal } from 'react-bootstrap';
import BrowserExtensionInstaller from '../../components/metamask/BrowserExtensionInstaller';
import MetamaskLogo from '../../components/metamask/MetamaskLogo';

const Metamask404 = () => {

  return (
    <Modal
      show
      backdrop="static"
      keyboard={false}
      className="sos-error-content"
    >
      <Modal.Header closeButton>
        <Modal.Title>Metamask 404</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MetamaskLogo className={'mt-2'} />
        <h5 className="mt-4 text-center">Please install metamask</h5>
      </Modal.Body>
      <Modal.Footer>
        <BrowserExtensionInstaller/>
      </Modal.Footer>
    </Modal>
  );
};

export default Metamask404;
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Spinner, Button, Col, Container, Row, Modal } from 'react-bootstrap';
import { Minter } from '../blockchain/Minter';
import SupplyStatus from '../components/minter/SupplyStatus';
import ImageContext from '../contexts/ImageContext';
import useMetamask from '../hooks/useMetamask';
import { POLYGON_URL } from '../setup';

const Home = () => {
  const { sosBanner } = useContext(ImageContext);
  const { 
    connect, 
    isActive, 
    account, 
    sendTransactionWithValue, 
    getTXReceipt, 
    shouldDisable,
    chainValid,
    requestChainChange,
  } = useMetamask();
  const [isMinting, setIsMinting] = useState(false);
  const [mintError, setMintError] = useState(false);
  const [mintSuccess, setMintSuccess] = useState(false);

  const disableMintBtn = useMemo(() => {
    return isMinting || shouldDisable;
  }, [shouldDisable, isMinting]);

  const handleMintClick = useCallback(async () => {
    const minter = new Minter();
    if(isActive && account) {
      let isNowValid = chainValid;
      if(!chainValid) {
        isNowValid = await requestChainChange();
      }
      console.log(isNowValid);
      setMintError(false);
      setMintSuccess(false);
      setIsMinting(true);
      const mintABI = minter.mintToken();
      try {
        const tx = await sendTransactionWithValue(mintABI, account);
        console.group('tx', tx);
        const hash = await getTXReceipt(tx);
        console.group('hash', hash);
        if(hash.status === 1) {
          setMintSuccess(hash);
        } else {
          throw new Error('Unable to mint, please try again later')
        }
      } catch(err) {
        console.log(err);
        setMintError(err);
      } finally {
        setIsMinting(false);
      }
    } else {
      connect();
    }
  }, [account, connect, isActive, getTXReceipt, sendTransactionWithValue, chainValid, requestChainChange]);

  const visitNFT = useCallback(() => {
    console.log(mintSuccess);
    console.log(POLYGON_URL);
  }, [mintSuccess]);

  return (
    <Container fluid className="my-auto">
      <Row className="justify-content-center">
        <Col xl={8} className="d-flex justify-content-center">
          <img src={sosBanner} alt="Save our Sharks" className="py-5"/>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <SupplyStatus/>
      </Row>
      <Row className="justify-content-center">
        <Col xl={8} className="d-flex justify-content-center">
          <span className="fs-3">MINTED</span>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xl={8} className="d-flex justify-content-center">
          <span className="fs-2">MINT PRICE: 222 MATIC</span>
        </Col>
      </Row>
      <Row className="justify-content-center my-4">
        <Col xl={8} className="d-flex justify-content-center">
          <Button variant="light" className="px-5 py-2" disabled={disableMintBtn} onClick={handleMintClick}>{(isActive && account) ? 'MINT NOW' : 'CONNECT TO METAMASK'}</Button>
        </Col>
      </Row>

      <Modal show={isMinting}>
        <Modal.Header>
          <Modal.Title>Minting NFT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Minting your NFT, please wait</p>
          <Spinner animation="grow" />
        </Modal.Body>
      </Modal>

      <Modal show={mintError} onHide={() => setMintError(false)}>
        <Modal.Header>
          <Modal.Title>Minting Failed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Sorry, we're unable to mint. Try again later.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setMintError(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleMintClick}>
            Try again
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={mintSuccess} onHide={() => setMintSuccess(false)}>
        <Modal.Header>
          <Modal.Title>Minted Successfully</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Want to check your NFT? Click the button below</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setMintSuccess(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={visitNFT}>
            Go to Opensea
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Home;
import React, { useEffect } from "react";
const ModelViewer = require('@metamask/logo');

const MetamaskLogo = ({ isSmall = false, className}) => {
  useEffect(() => {
  // To render with fixed dimensions:
  const viewer = ModelViewer({
    // Dictates whether width & height are px or multiplied
    pxNotRatio: true,
    width: isSmall ? 30 : 300,
    height: isSmall ? 24 : 240,
    // pxNotRatio: false,
    // width: 0.9,
    // height: 0.9,

    // To make the face follow the mouse.
    followMouse: false,

    // head should slowly drift (overrides lookAt)
    slowDrift: false,
  });

  // add viewer to DOM
  const container = document.getElementById(isSmall ? 'metamask-logo-container-small' : 'metamask-logo-container');
  container.appendChild(viewer.container);

  // look at something on the page
  viewer.lookAt({
    x: 100,
    y: 100,
  });

  // enable mouse follow
  viewer.setFollowMouse(true);

  // deallocate nicely
  viewer.stopAnimation();
  }, [isSmall]);

  return (
    isSmall ?
    <div id="metamask-logo-container-small" className={className} /> :
    <div id="metamask-logo-container" className={className} />
  );
};

export default MetamaskLogo;
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const Error404 = ({ text }) => {

  return (
    <Modal
      show
      backdrop="static"
      keyboard={false}
      className="sos-error-content"
    >
      <Modal.Header closeButton>
        <Modal.Title>{text ?? 'Error 404'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        The page you're looking for is not valid.
      </Modal.Body>
      <Modal.Footer>
        <Button href="/" variant="primary">Go back to home page</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Error404;
const MINTER_CONTRACT_ADDRESSES = {
  TEST: '0x08575C83E50346752710799F5fF57FdfFcfBeAC7',
  PROD: '0x0bD6aff252c5F43c29ed39cDd9708Ec4A7282562',
}

const POLYGON_URLS = {
  TEST: 'https://testnets.opensea.io/assets/mumbai/',
  PROD: 'https://opensea.io/',
}

const MINTING_COSTS = {
  TEST: 1,
  PROD: 222,
}

// export const app_server = process.env.REACT_APP_SERVER
export const app_server = 'PROD'
// export const app_server = 'TEST'

const SOSMinterJSON = require(`./blockchain/jsons/${app_server}/SOSMinter.json`);
const MINTER_CONTRACT_ADDRESS = MINTER_CONTRACT_ADDRESSES[app_server];

const POLYGON_URL = POLYGON_URLS[app_server]
const MINTING_COST = MINTING_COSTS[app_server]

export { MINTER_CONTRACT_ADDRESS, SOSMinterJSON, POLYGON_URL, MINTING_COST };


import { MINTER_CONTRACT_ADDRESS, SOSMinterJSON } from "../setup";

var Web3 = require("web3");
class Minter{
    ContractObj; 
    constructor(contractAddress = MINTER_CONTRACT_ADDRESS){
      console.log(contractAddress);
      this.contract_address = contractAddress;
      // let web3_obj = new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_INFURA_URL));
      let web3_obj = new Web3(window.ethereum);
      this.ContractObj = new web3_obj.eth.Contract(SOSMinterJSON?.abi ?? [], contractAddress)
    }

    // Bernabe : Returns ABI code for metamask to use to execute burnToken function, a function that let user burn the token for good  
    // Required :  tokenID - the id of NFT 
    burnToken(tokenID){
      return this.ContractObj.methods.burnToken(tokenID).encodeABI();
    }
    // Bernabe : TODO : make web3 check if the transaction is mined. this will return only the transaction address. Function : to mint metadata to be included to blockchain 
    // Required :  minter_address - the address of user
    //             token_metadata - token metadata. refer to ERC721 metadata standard for proper format 
    mintToken(){
      return this.ContractObj.methods.mintToken().encodeABI();
    }

    async getRemainingSupply() {
      return this.ContractObj.methods.gotCount().call();
    }

    async getTotalSupply() {
      return this.ContractObj.methods.totalSupply().call();
    }

    _handleError(response){
        if (response) { // res.status >= 200 && res.status < 300
            return response;
        } else {
            // eslint-disable-next-line no-throw-literal
            throw `function app responded HTTP ${response.status} ${response.statusText}}`; // Bernabe : TODO : specific error scenario handling
        }
    }
}

export { Minter };
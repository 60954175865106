import React from "react"
import MetamaskContext from "../contexts/MetamaskContext"

export default function useMetamask() {
  const context = React.useContext(MetamaskContext)

  if (context === undefined) {
    throw new Error('useMetamask hook must be used with a MetamaskProvider component')
  }

  return context
}
import { library } from '@fortawesome/fontawesome-svg-core';


import {
  faDiscord, faTwitter
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faDiscord,
  faTwitter,
);

/* eslint-disable react/style-prop-object */
import React from 'react';
import { Container } from 'react-bootstrap';
import TopNavigation from './TopNavigation';

const AppLayout = ({ children }) => {

  return (
    <div id="body">
      <TopNavigation/>
      <Container id="page-container">
        {children}
      </Container>
    </div>
  );
};

export default AppLayout;
import React, { useMemo } from 'react';

const ImageContext = React.createContext({});

export const ImageProvider = ({ children }) => {
  const images = useMemo(() => {
    return {
      sharks: [
        `${process.env.PUBLIC_URL}/sharks/1.png`,
        `${process.env.PUBLIC_URL}/sharks/2.png`,
        `${process.env.PUBLIC_URL}/sharks/3.png`,
        `${process.env.PUBLIC_URL}/sharks/4.png`,
        `${process.env.PUBLIC_URL}/sharks/5.png`,
        `${process.env.PUBLIC_URL}/sharks/6.png`,
        `${process.env.PUBLIC_URL}/sharks/7.png`,
        `${process.env.PUBLIC_URL}/sharks/8.png`,
        `${process.env.PUBLIC_URL}/sharks/9.png`,
        `${process.env.PUBLIC_URL}/sharks/10.png`,
      ],
      mainBG: `${process.env.PUBLIC_URL}images/background.png`,
      sosBanner: `${process.env.PUBLIC_URL}images/SOS-Save-Our-Sharks.png`,
      sosLogo: `${process.env.PUBLIC_URL}images/SOS-Logo.jpg`,
    };
  }, []);

  return (
    <ImageContext.Provider
      value={images}
    >
      {children}
    </ImageContext.Provider>
  );
}

export default ImageContext;
import React from 'react';
import { Modal } from 'react-bootstrap';

const Browser401 = () => {

  return (
    <Modal
      show
      backdrop="static"
      keyboard={false}
      className="sos-error-content"
    >
      <Modal.Header closeButton>
        <Modal.Title>Browser Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        The browser you're using is not supported kindly use another browsers.
      </Modal.Body>
    </Modal>
  );
};

export default Browser401;
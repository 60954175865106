import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { convertWeiTo } from '../helpers/TextFormatter';
import { InjectedConnector } from '@web3-react/injected-connector';
import { useNavigate, useLocation } from 'react-router-dom';
import { app_server, MINTER_CONTRACT_ADDRESS, MINTING_COST } from '../setup';
import Web3 from 'web3';

const MetamaskContext = React.createContext(null);

const CHAIN_IDS = {
  PROD: [137],
  TEST: [80001],
};

export const NETWORK_HEX_IDS = {
  80001: '0x13881',
  137: '0x89'
}

// export const SUPPORTED_CHAIN_IDS = CHAIN_IDS[process.env.REACT_APP_SERVER];
export const SUPPORTED_CHAIN_IDS = CHAIN_IDS[app_server];

export const injected = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
});

export const MetamaskProvider = ({ children }) => {
  const { activate, account, active, chainId } = useWeb3React();
  const navigate = useNavigate();
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const [chainValid, setChainValid] = useState(chainId && injected.supportedChainIds.includes(chainId));
  const [isChangingChain, setIsChangingChain] = useState(false);

  const { ethereum_obj, eth_provider } = useMemo(() => {
    const ethObj = window.ethereum;
    let ethProvider = null;
    if(ethObj) {
      ethProvider = new ethers.providers.Web3Provider(ethObj);
    }
    return {
      ethereum_obj: ethObj,
      eth_provider: ethProvider,
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);
  const [walletBalance, setWalletBalance] = useState(0);
  const [shouldDisable, setShouldDisable] = useState(false) // Should disable connect button while connecting to MetaMask

  useEffect(() => {
    if(eth_provider && account && chainValid) {
      eth_provider.getBalance(account)
      .then((balance) => setWalletBalance(convertWeiTo({ price: balance?.toString() ?? 0 })))
      .catch((err) => console.error(err));
    }
  }, [account, eth_provider, chainValid, chainId]);

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  const handleChainChanged = useCallback((currChainID) => {
    if(currChainID && typeof currChainID === 'string' && currChainID.toLowerCase().startsWith('0x')) {
      setChainValid(injected.supportedChainIds.includes(parseInt(currChainID, 16)));
    } else {
      setChainValid(injected.supportedChainIds.includes(currChainID));
    }
  }, []);
  
  useEffect(() => {
    if(ethereum_obj) {
      ethereum_obj.on('chainChanged', handleChainChanged);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ethereum_obj]);

  useEffect(() => {
    handleChainChanged(chainId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);

  const requestChainChange = useCallback(async () => {
    setIsChangingChain(true);
    const supportedFirstChainID = SUPPORTED_CHAIN_IDS[0];
    await ethereum_obj.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: NETWORK_HEX_IDS[supportedFirstChainID] }], // chainId must be in hexadecimal numbers
    });
    setIsChangingChain(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ethereum_obj]);

  // Connect to MetaMask wallet
  const connect = useCallback(async () => {
    if(ethereum_obj) {
      setShouldDisable(true);
      try {
        if (!injected.supportedChainIds.includes(chainId)) {
          await requestChainChange();
        }
        await activate(injected);
      } catch (error) {
        console.error('Error on connecting: ', error);
      }
      setShouldDisable(false);
    } else {
      navigate('errors/metamask404', { state: location.state });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ethereum_obj]);

  // Bernabe : make user sign a authentication message 
  const signMessage = useCallback(async (message) => {
    if(eth_provider) {
      const signer = eth_provider.getSigner(); 
      const signature = await signer.signMessage(message);
      return signature;
    } else {
      alert("No web wallet installed. Please install one and reload the page");
    }
    return null;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eth_provider]);
  
  // Bernabe : send a transaction to metamask
  const sendTransaction = useCallback(async (ABI, wallet_address, contractAddress) => {
    if(ethereum_obj) {
      const transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: wallet_address, // must match user's active address.
        data: ABI, // Optional, but used for defining smart contract creation and interaction.
      };
      // console.log(new Web3().utils.toHex(Math.pow(10, 11)), Math.pow(10, 11))
      // txHash is a hex string
      // As with any RPC call, it may throw an error
      return await ethereum_obj.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      });
    } else {
      navigate('errors/metamask404', { state: location.state });
      return null;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ethereum_obj]);

  const sendTransactionWithValue = useCallback(async (ABI, wallet_address, contract_address = MINTER_CONTRACT_ADDRESS) => {
    if(ethereum_obj) {
      const transactionParameters = {
        to: contract_address, // Required except during contract publications.
        from: wallet_address, // must match user's active address.
        data: ABI, // Optional, but used for defining smart contract creation and interaction.
        value : new Web3().utils.toHex(MINTING_COST * Math.pow(10, 18)),
        // gasPrice: (.1 * Math.pow(10, 9)) + '',
        // gasPrice: new Web3().utils.toHex(Math.pow(10, 9)),
        gas: (11000000).toString(16),
      };

      // txHash is a hex string
      // As with any RPC call, it may throw an error
      const txHash = await ethereum_obj.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      });
      return txHash;
    } else {
      navigate('errors/metamask404', { state: location.state });
      return null;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ethereum_obj]);

  // Bernabe : query transaction status
  const getTXReceipt = useCallback(async (txHash) => {
    let receipt = null
    while (receipt === null) {
      receipt = await eth_provider.getTransactionReceipt(txHash);
    }
    return receipt
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eth_provider]);

  const values = useMemo(
    () => ({
      hasWebWallet: !!ethereum_obj,
      isActive,
      account,
      connect,
      signMessage,
      sendTransaction,
      sendTransactionWithValue,
      getTXReceipt,
      requestChainChange,
      walletBalance,
      shouldDisable,
      chainValid,
      isChangingChain,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ethereum_obj, isActive, shouldDisable, account, walletBalance, chainValid, isChangingChain]
  )

  return <MetamaskContext.Provider value={values}>{children}</MetamaskContext.Provider>
}

export default MetamaskContext;
import React, { useContext } from 'react';
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import ImageContext from '../../contexts/ImageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TopNavigation = () => {
  const { sosLogo } = useContext(ImageContext);

  return (
    <Navbar className="sos-bg-dark" variant="dark" expand="lg" sticky="top">
      <Container fluid className="sos-top-nav">
        <Row className="w-100 justify-content-md-between my-3 mx-4">
          <Col xs={4}>
            <Navbar.Brand href="https://shark.octopusandwhale.com/" className="serif-text" >
              <img src={sosLogo} alt="SoS Logo"/>
            </Navbar.Brand>
          </Col>
          <Col xs={8}>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto w-100 justify-content-md-end">
                <Nav.Link href="https://shark.octopusandwhale.com/#About">ABOUT</Nav.Link>
                <Nav.Link href="https://shark.octopusandwhale.com/#Team">FAQ</Nav.Link>
                <Nav.Link href="https://shark.octopusandwhale.com/#Team">TEAM</Nav.Link>
                <Nav.Link href="https://discord.com/invite/XKQfVH6Jeg" className="d-flex justify-content-center align-items-center">
                  JOIN DISCORD 
                  <FontAwesomeIcon icon={'fa-brands fa-discord'} className="mx-2"/>
                </Nav.Link>
                <Nav.Link href="https://twitter.com/SOSnfts?t=2pE50bEv9Pysg8BBSFxNrg&s=09">
                  TWITTER
                  <FontAwesomeIcon icon={'fa-brands fa-twitter'} className="mx-2"/>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
};

export default TopNavigation;
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MetamaskProvider } from './contexts/MetamaskContext';
import { BrowserRouter } from 'react-router-dom';
import { ImageProvider } from './contexts/ImageContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import './utils/font-awesome';
import Web3 from 'web3'
import { Web3ReactProvider } from '@web3-react/core';

function getLibrary(provider) {
  return new Web3(provider)
}


ReactDOM.render(
  <React.StrictMode>
    <ImageProvider>
      <Suspense fallback={<div className="loading" />}>
        <BrowserRouter>
          <Web3ReactProvider getLibrary={getLibrary}>
            <MetamaskProvider>
              <App/>
            </MetamaskProvider>
          </Web3ReactProvider>
        </BrowserRouter>
      </Suspense>
    </ImageProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useEffect } from 'react';
import AppLayout from './components/layouts/AppLayout';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Home from './pages/home';
import Error404 from './pages/errors/error404';
import Metamask404 from './pages/errors/metamask404';
import useMetamask from './hooks/useMetamask';
import browserSupported from './helpers/Browser';
import Browser401 from './pages/errors/browser401';

const IS_BROWSER_SUPPORTED = browserSupported();

function App() {
  const { hasWebWallet } = useMetamask();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if(!hasWebWallet) {
      navigate('errors/metamask404', { state: location.state });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasWebWallet]);

  return (
    IS_BROWSER_SUPPORTED ?
    <AppLayout>
      <Routes>
        <Route index element={<Home/>}/>
        <Route path="errors">
          <Route
            path="metamask404"
            element={<Metamask404/>}
          />
        </Route>
        <Route path="*" element={<Error404/>} />
      </Routes>
    </AppLayout> :
    <Browser401/>
  );
}

export default App;
